import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { User } from '../types/user.type';
import { userStore } from '../../stores/UserStore.atom';

import ThrowNotification from '../../components/ThrowNotification';
import customerService from '../../API/customer/Customer.service';
import { CloseIcon, LoadingIcon, MasterCardIcon, TrashIcon, VisaIcon } from '../../Icon';
import classNames from 'classnames';
import PaymentMethodModal from './components/PaymentMethodModal';
import SwitchRadio from './components/SwitchRadio';
import { Subscription } from './types/Subscription.type';
import { PaymentMethod } from './types/PaymentMethod.type';
import { InitialActiveStatusUtils } from './utils/initialActiveStatus.utils';

export const PaymentMethodePage: React.FC = () => {
  const user = useRecoilValue<User | undefined>(userStore);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [subscription, setSubscription] = useState<Subscription>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [activeStatus, setActiveStatus] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<{ type: string; message: string }>({
    type: '',
    message: ''
  });

  const toggleSwitch = async (index: number, paymentMethod: string) => {
    setActiveStatus({ [index]: true });

    if (user && subscription) {
      try {
        const id = user.id.split('/');
        await customerService
          .updatePaymentMethodToSubscription(id[4], user.metafield.value, {
            paymentMethodId: paymentMethod,
            subscriptionId: subscription.id
          })
          .then((res) => {
            if (res.status === 200) {
              showNotification(
                'success',
                `Votre moyen de paiement a bien été associé à votre abonnement `
              );
              fetchCustomer(user.id, user.metafield.value);
            }
          });
      } catch (error) {
        showNotification('error', `Une erreur s'est produite veuillez recommencer plus tard`);
        console.error('Error fetching data:', error);
      }
    }
  };

  const fetchCustomer = async (customerId: string, stripeCustomerId: string) => {
    try {
      const id = customerId.split('/');
      setIsLoading(true);

      const tmp = await customerService.getAllPaymentMethod(id[4], stripeCustomerId);
      const subscription = await customerService.getSubscription(id[4], stripeCustomerId);
      setSubscription(subscription.data);
      const reorderedPaymentMethods = [...tmp.data.data];
      reorderedPaymentMethods.sort((a, b) => {
        if (a.id === subscription.data.default_payment_method) return -1;
        if (b.id === subscription.data.default_payment_method) return 1;
        return 0;
      });
      if (reorderedPaymentMethods) {
        setActiveStatus(
          InitialActiveStatusUtils(
            reorderedPaymentMethods,
            subscription.data.default_payment_method
          )
        );
      }
      setPaymentMethods(reorderedPaymentMethods);
      setIsLoading(false);
    } catch (error) {
      showNotification('error', `Une erreur s'est produite veuillez recommencer plus tard`);
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (
      user &&
      user.metafield &&
      user.metafield.value &&
      user.metafield.key === 'stripeCustomerId'
    ) {
      fetchCustomer(user.id, user.metafield.value);
    }
  }, [user]);

  const closeModal = async () => {
    setModalOpen(false);
    if (
      user &&
      user.metafield &&
      user.metafield.value &&
      user.metafield.key === 'stripeCustomerId'
    ) {
      await fetchCustomer(user.id, user.metafield.value);
    }
  };

  const deletePaymentMethod = async (paymentMethodId: string) => {
    if (user && user.metafield.key === 'stripeCustomerId') {
      const id = user.id.split('/');
      try {
        await customerService
          .deletePaymentMethodById(id[4], user.metafield.value, paymentMethodId)
          .then((res) => {
            if (res.status === 200) {
              showNotification('success', `Moyen de paiement supprimer `);
              fetchCustomer(user.id, user.metafield.value);
            }
          });
      } catch (e) {
        showNotification('error', `Une erreur s'est produite veuillez recommencer plus tard`);
        console.error(e);
      }
    }
  };

  const showNotification = (type: string, message: string) => {
    setNotification({ type, message });
    setTimeout(() => {
      setNotification({ type: '', message: '' });
    }, 3000);
  };

  return (
    <div className="gap-y-2 flex flex-col">
      <div className="pb-2 uppercase text-lg font-lato">Moyen de paiement</div>

      {notification.type && (
        <ThrowNotification message={notification.message} type={notification.type} />
      )}
      {isLoading ? (
        <div className="w-full h-full flex-col font-lato items-center z-50 flex justify-center py-32">
          <LoadingIcon />
          En cours de chargement
        </div>
      ) : paymentMethods && paymentMethods.length > 0 ? (
        paymentMethods.map((paymentMethod: PaymentMethod, index: number) => (
          <div
            key={paymentMethod.id}
            className="flex items-center bg-white justify-around h-16 gap-x-4 w-2/3 verticalMobile:w-full px-2 border-separate border border-[#f5f0e8]">
            <div className="font-lato text-sm flex w-[10%] gap-x-2 flex-wrap">
              {paymentMethod.card.brand === 'visa' ? <VisaIcon /> : <MasterCardIcon />}
            </div>
            <div className="font-lato text-sm w-[30%] h-full items-center flex">
              <span className="mt-1 mr-2 verticalMobile:hidden">**** **** ****</span>
              <span>{paymentMethod.card.last4}</span>
            </div>
            <div className="font-lato text-sm flex w-[10%] items-center">
              <span>{paymentMethod.card.exp_month}</span>
              <span>/</span>
              <span>{paymentMethod.card.exp_year}</span>
            </div>
            <div id={paymentMethod.id}>
              <SwitchRadio
                activeStatus={activeStatus}
                index={index}
                paymentMethodId={paymentMethod.id}
                onChange={toggleSwitch}></SwitchRadio>
            </div>
            <button
              type="submit"
              onClick={() => deletePaymentMethod(paymentMethod.id)}
              className={classNames(
                paymentMethod.id === subscription?.default_payment_method &&
                  'pointer-events-none opacity-40'
              )}>
              <TrashIcon />
            </button>
          </div>
        ))
      ) : (
        <div className="w-full h-full flex-col font-lato items-center z-50 flex justify-center py-32">
          Aucun moyen de paiement enregister
          <span className="font-lato text-sm opacity-50">
            Pour enregistrer votre premier moyen de paiement passait votre première commande{' '}
          </span>
        </div>
      )}
      {paymentMethods && paymentMethods.length === 0 && (
        <div className="w-full h-full flex-col font-lato items-center z-50 flex justify-center py-32">
          Aucun moyen de paiement enregister
        </div>
      )}

      <button
        type="submit"
        onClick={() => setModalOpen(true)}
        className={classNames(
          'bg-[#222122] absolute top-0 p-2 right-2 text-white hover:bg-white text-sm hover:text-black hover:border-[#222122] hover:border font-lato verticalMobile:hidden'
        )}>
        Ajouter une carte
      </button>
      <button
        type="submit"
        onClick={() => setModalOpen(true)}
        className={classNames(
          'bg-[#222122] fixed bottom-10 p-2 right-2 rounded-full rotate-45 text-white hover:bg-white hover:text-black hover:border-[#222122] hover:border font-lato sm:hidden'
        )}>
        <CloseIcon />
      </button>
      {modalOpen && <PaymentMethodModal onClose={closeModal} showNotification={showNotification} />}
    </div>
  );
};
