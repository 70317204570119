import React, { useEffect, useState } from 'react';

import { CloseIcon } from '../../../Icon';
import { useRecoilValue } from 'recoil';
import { userStore } from '../../../stores/UserStore.atom';
import { User } from '../../types/user.type';
import classNames from 'classnames';
import { OrderInfo } from '../../../@types/Product.type';
import ordersService from '../../../API/orders/Orders.service';
import checkoutService from '../../../API/checkout/Checkout.service';
import { BuyingInfo } from '../../types/buyingInfo.type';
import ValidationPaymentNotification from './ValidationPaymentNotification';
import { toast } from 'react-toastify';

interface BuyingModalProps {
  orderInfo: OrderInfo;
  onClose: () => void;
}

const BuyingModal: React.FC<BuyingModalProps> = ({ onClose, orderInfo }) => {
  const user = useRecoilValue<User | undefined>(userStore);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPaymentConfirm, setIsPaymentConfirm] = useState<boolean>(false);
  const [productInfo, setProductInfo] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productVariantId = orderInfo?.product.id.split('/');
        const productInfo = await ordersService.returnProductVariantsById(productVariantId[4]);
        setProductInfo(productInfo.data);
      } catch (error) {
        toast.error(`Une erreur s'est produite veuillez recommencer plus tard`);
        console.error('Error fetching data:', error);
      }
    };
    if (orderInfo?.product) {
      fetchData();
    } else {
      toast.error(`Une erreur s'est produite veuillez recommencer plus tard`);
    }
  }, [orderInfo]);

  const buyProduct = async () => {
    if (user && user.metafield.key === 'stripeCustomerId') {
      try {
        setIsLoading(true);
        const buyingInfo: BuyingInfo = {
          price: (
            (productInfo.product.variants.nodes[0].inventoryItem.unitCost.amount -
              productInfo.product.variants.nodes[0].price) *
            1.2
          ).toFixed(2),
          productVariantId: orderInfo.product.variants.nodes[0].id,
          productName: orderInfo.name,
          currencyCode: productInfo.product.variants.nodes[0].inventoryItem.unitCost.currencyCode,
          orderId: orderInfo.orderId,
          productId: orderInfo.product.id
        };
        const invoice = await checkoutService.buyProductOrder(user.metafield.value, { buyingInfo });
        if (invoice.status === 201) {
          toast.success(`Achat confirmé, Merci`);
          setIsLoading(false);
          setIsPaymentConfirm(true);
        }
      } catch (e) {
        toast.error(`Une erreur s'est produite veuillez recommencer plus tard`);
        console.error(e);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="fixed z-40 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex w-full justify-end">
              <button onClick={onClose} type="button" className="">
                <CloseIcon />
              </button>
            </div>
            <div className="sm:flex sm:items-start">
              {productInfo ? (
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex gap-x-3 w-full">
                  {orderInfo?.image && (
                    <img
                      src={orderInfo?.image.url}
                      alt="logo.codress"
                      className="object-fit w-10"
                    />
                  )}
                  <div className="flex flex-col w-full">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900 font-lato"
                      id="modal-headline">
                      {orderInfo.name}
                    </h3>
                    <div className="mt-2 font-lato flex flex-col items-start w-full">
                      <div className="flex w-full flex-row justify-between">
                        <p className="text-sm text-gray-500">
                          Prix publique:{' '}
                          <span className="line-through	">
                            {productInfo?.product?.metafield?.value + ' €' ?? 'Indisponible'}
                          </span>
                        </p>
                        <p className="text-sm text-gray-500">{orderInfo.orderName}</p>
                      </div>
                      <p className="">
                        Prix Codress:{' '}
                        {(
                          (productInfo?.product?.variants?.nodes[0]?.inventoryItem?.unitCost
                            ?.amount -
                            productInfo?.product?.variants?.nodes[0]?.price) *
                          1.2
                        ).toFixed(2)}
                        {' €'}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" animate-pulse flex w-full flex-row h-auto items-center p-6 justify-center gap-x-3">
                  <div className="flex-col gap-4 flex">
                    <div className="w-6 h-6 border-2 text-black text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-gray-800 rounded-full"></div>
                  </div>
                  <span className="text-black text-[15px] font-normal leading-[22.5px] font-lato">
                    {`Récuperation des informations en cours`}
                  </span>
                </div>
              )}
            </div>
            <div className="flex w-full h-full justify-center items-center ">
              {isLoading ? (
                <div className="flex w-full flex-row h-auto items-center p-6 justify-center gap-x-3">
                  <div className="flex-col gap-4 flex">
                    <div className="w-6 h-6 border-2 text-black text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-gray-800 rounded-full"></div>
                  </div>
                  <span className="text-black text-[15px] font-normal leading-[22.5px] font-lato">
                    {`Achat en cours de traitement`}
                  </span>
                </div>
              ) : isPaymentConfirm ? (
                <ValidationPaymentNotification onClose={onClose} />
              ) : (
                <div className="px-4 py-3 gap-y-4 sm:px-6 flex flex-col w-full">
                  <button
                    className={classNames(
                      (orderInfo.fulfillmentStatus !== 'fulfilled' ||
                        orderInfo.status === 'Retour') &&
                        'opacity-50 pointer-events-none',
                      'bg-[#222122] flex justify-center items-center p-4 w-full group hover:bg-white hover:outline hover:outline-1 hover:outline-black'
                    )}
                    onClick={buyProduct}>
                    <span className="text-white text-[15px] group-hover:text-black font-normal leading-[22.5px] uppercase font-lato">
                      {`Acheter l'article`}
                    </span>
                  </button>
                  <button
                    onClick={onClose}
                    type="button"
                    className="w-full justify-center border border-[#222122] shadow-sm p-4 text-base font-medium text-black font-lato">
                    Fermer
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BuyingModal;
