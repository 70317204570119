import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { User } from '../../types/user.type';
import { userStore } from '../../../stores/UserStore.atom';
import customerService from '../../../API/customer/Customer.service';
import { CustomerInvoicesEntity } from '../../../API/customer/CustomerInvoices.entity';
import { getDateFromTimestampUtils } from '../utils/getDateFromTimestampUtils';
import ThrowNotification from '../../../components/ThrowNotification';

export const UncomingInvoice: React.FC = () => {
  const [invoice, setInvoice] = useState<CustomerInvoicesEntity>();
  const user = useRecoilValue<User | undefined>(userStore);
  const [notification, setNotification] = useState<{ type: string; message: string }>({
    type: '',
    message: ''
  });

  const showNotification = (type: string, message: string) => {
    setNotification({ type, message });
    setTimeout(() => {
      setNotification({ type: '', message: '' });
    }, 3000);
  };

  useEffect(() => {
    const fetchCustomer = async (customerId: string, stripeCustomerId: string) => {
      try {
        const id = customerId.split('/');
        const invoices = await customerService.getNextInvoice(id[4], stripeCustomerId);
        setInvoice(invoices.data);
      } catch (error) {
        showNotification('error', `Une erreur c'est produite veuillez recommencer plus tard`);
        console.error('Error fetching data:', error);
      }
    };
    if (user) {
      if (user.metafield && user.metafield?.key === 'stripeCustomerId' && user.metafield.value) {
        console.log(user);
        fetchCustomer(user.id, user.metafield.value);
      }
    }
  }, [user]);
  //€
  return (
    <div className="gap-y-2 flex flex-col">
      {notification.type && (
        <ThrowNotification message={notification.message} type={notification.type} />
      )}
      {invoice && (
        <div
          key={invoice.id}
          className="flex items-center bg-white justify-around h-16 gap-x-4 w-full verticalMobile:w-full  px-2 border-separate border border-[#f5f0e8]">
          <div className="font-lato text-sm flex w-[20%] gap-x-2 flex-wrap">
            <span>{getDateFromTimestampUtils(invoice.period_start)}</span> -
            <span>{getDateFromTimestampUtils(invoice.period_end)}</span>
          </div>
          <div className="font-lato text-sm w-[10%]"> {invoice.total / 100} €</div>
          <div className="font-lato text-sm bg-green-400 rounded-xl min-w-[10%] w-auto px-3 text-center">
            {invoice.status === 'draft' ? 'A venir' : 'Erreur'}
          </div>
        </div>
      )}
    </div>
  );
};
