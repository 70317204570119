import axios from 'axios';
import { User } from '../../pages/types/user.type';
const base_url = process.env.REACT_APP_DEVELOP_API;

const ordersService = {
  async getAllOrders(customerId: string, filters: { NextOrBefore: string; index: string }) {
    return axios.get(`${base_url}/orders/all/${customerId}`, { params: filters });
  },

  async returnOrderItemById(orderId: string, user: User | undefined, orderInfo: any) {
    return axios.post(`${base_url}/orders/return/${orderId}`, { user, orderInfo });
  },
  async returnProductVariantsById(productVariantId: string) {
    return axios.get(`${base_url}/orders/product/variants/${productVariantId}`);
  },

  async returnOrderGenerateTicket(orderId: string, user: User | undefined) {
    return axios.post(`${base_url}/orders/return/ticket/${orderId}`, { user });
  }
};
export default ordersService;
