import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import ordersService from '../../API/orders/Orders.service';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { GET_CUSTOMER } from '../../API/queries/Customer.query';
import ProductModal from './components/ProductModal';
import { LoadingIcon, NoImageIcon } from '../../Icon';
import { Order } from '../../API/orders/Order.entity';
import BuyingModal from './components/BuyingModal';
import { OrderInfo } from '../../@types/Product.type';
import DeliveryStepper from './components/DeliveryStepper';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export default function WardrobePage() {
  const { auth } = useAuth();
  const [orders, setOrders] = useState<Order>();
  const [selectedRow, setSelectedRow] = useState<OrderInfo>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [buyingModalOpen, setBuyingModalOpen] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data } = useQuery(GET_CUSTOMER, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    },
    variables: { customerAccessToken: auth.accessToken }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = data?.customer?.id.split('/');
        setIsLoading(true);
        const orders = await ordersService.getAllOrders(id[4], {
          NextOrBefore: 'next',
          index: 'null'
        });
        setOrders({ pageInfo: orders.data.pageInfo, products: orders.data.products });
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (data?.customer) {
      fetchData();
    }
  }, [data]);

  const handlePageProduct = async (hasPage: string, index: string) => {
    try {
      const id = data?.customer?.id.split('/');
      const orders = await ordersService.getAllOrders(id[4], {
        NextOrBefore: hasPage,
        index
      });
      if (orders.data.products.length === 0) {
        handlePageProduct(
          hasPage,
          hasPage === 'before' ? orders.data.pageInfo.startCursor : orders.data.pageInfo.endCursor
        );
      }
      setOrders({ pageInfo: orders.data.pageInfo, products: orders.data.products });
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = (order: OrderInfo) => {
    setSelectedRow(order);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedRow(undefined);
    setModalOpen(false);
  };

  const buyingCloseModal = () => {
    setBuyingModalOpen(false);
  };

  return (
    <div id="wardrobe-page">
      <div className="flex justify-start w-full">
        <div className="uppercase text-lg	font-lato">Mes Commandes</div>
      </div>
      <div className="flex gap-x-5 items-center w-full justify-end pb-1">
        <div
          className={classNames(
            orders?.pageInfo.hasPreviousPage
              ? 'uppercase text-xs font-lato cursor-pointer'
              : 'uppercase text-xs font-lato opacity-50 pointer-events-none'
          )}
          onClick={() => {
            orders && handlePageProduct('before', orders.pageInfo.startCursor);
            setPageIndex(pageIndex - 1);
          }}>
          Précédent
        </div>
        <span className="text-xs font-lato opacity-50">{pageIndex}</span>
        <div
          className={classNames(
            orders?.pageInfo.hasNextPage
              ? 'uppercase text-xs font-lato cursor-pointer'
              : 'uppercase text-xs font-lato opacity-50 pointer-events-none'
          )}
          onClick={() => {
            orders && handlePageProduct('next', orders.pageInfo.endCursor);
            setPageIndex(pageIndex + 1);
          }}>
          Suivant
        </div>
      </div>
      <div className="relative gap-y-4 flex flex-col overflow-y-auto verticalMobile:max-h-[700px] max-h-[600px] verticalMobile:pb-6">
        {orders?.products &&
          orders?.products?.length > 0 &&
          orders?.products?.map((order: OrderInfo) => (
            <div
              key={order?.id}
              className={`w-full flex flex-row justify-center items-center gap-x-3 h-40 verticalMobile:flex-col mobile:h-auto p-3 bg-white border border-[#f5f0e8]`}>
              <div
                id="imageProduct"
                className={classNames(!order?.image && 'verticalMobile:mb-3', 'w-20')}>
                {order?.image ? (
                  <img src={order?.image.url} alt="logo.codress" className="object-cover w-20" />
                ) : (
                  <NoImageIcon />
                )}
              </div>
              <div id="dataSTepper" className="flex-1 h-full">
                <div className="w-full h-1/2 flex flex-col">
                  <div className="flex justify-between flex-row">
                    <div className="font-medium text-gray-900 font-lato w-full">{order.name}</div>
                    <div className="text-gray-900 text-xs font-lato opacity-50 flex flex-wrap gap-x-1 justify-end w-auto">
                      <span>{'Debut de location:'}</span>
                      <span>
                        {format(new Date(order.createdAt.split('T')[0]), 'dd MMMM yyyy', {
                          locale: fr
                        })}
                      </span>
                    </div>
                  </div>

                  <div className="font-medium text-gray-900 font-lato opacity-50 text-sm">
                    {order.orderName}
                  </div>
                </div>
                <div className="w-full h-1/2 verticalMobile:py-3">
                  <DeliveryStepper status={order.status} />
                </div>
              </div>
              <div
                id="action"
                className="flex flex-col gap-y-2 h-full w-1/4 verticalMobile:w-full items-center justify-center p-2">
                <button
                  className="bg-[#222122] group hover:bg-white hover:outline outline-1 hover:outline-[#222122] flex justify-center items-center p-2 w-full"
                  onClick={() => {
                    setBuyingModalOpen(true);
                    setSelectedRow(order);
                  }}>
                  <span className="text-white group-hover:text-black text-[13px] font-normal leading-[22.5px] uppercase font-lato">
                    Acheter
                  </span>
                </button>
                <button
                  className="hover:bg-[#222122] bg-white outline-1 outline outline-[#222122]/50 group flex justify-center items-center p-2 w-full"
                  onClick={() => openModal(order)}>
                  <span className="group-hover:text-white text-black text-[13px] font-normal leading-[22.5px] uppercase font-lato">
                    Retourner
                  </span>
                </button>
              </div>
            </div>
          ))}
        {isLoading && (
          <div className="w-full h-full flex-col items-center z-50 flex justify-center py-32">
            <LoadingIcon />
            En cours de chargement
          </div>
        )}
        {orders?.products?.length === 0 && (
          <div className="w-full h-full flex-col items-center z-50 flex justify-center py-32">
            Pas de commande
          </div>
        )}
      </div>
      {modalOpen && selectedRow && <ProductModal orderInfo={selectedRow} onClose={closeModal} />}
      {buyingModalOpen && selectedRow && (
        <BuyingModal orderInfo={selectedRow} onClose={buyingCloseModal} />
      )}
    </div>
  );
}
