import React from 'react';
import { Formik, Field, Form } from 'formik';
import { useMutation } from '@apollo/client';
import { CUSTOMER_RECOVER } from '../../../API/queries/Customer.query';
import { ArrowLeftIcon } from '../../../Icon';
import { toast } from 'react-toastify';

interface ResetPasswordFormProps {
  remove: () => void;
}
export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ remove }) => {
  const [recoverCustomerQuery] = useMutation<any>(CUSTOMER_RECOVER, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    }
  });

  const resetForm = async (values: any) => {
    const response = await recoverCustomerQuery({
      variables: {
        email: values.email
      }
    });
    if (response && response.data.customerRecover.userErrors.length > 0) {
      toast.error("Une erreur s'est produite veuillez recommencer plus tard.");
    } else {
      toast.success('Vous devriez recevoir rapidement un email avec de plus amples instructions.');
    }
  };
  return (
    <div className="bg-white flex flex-col py-[30px] px-[24px] justify-end items-start w-full border border-solid border-gray-100 shadow-non">
      <div className="flex w-full items-center gap-x-3">
        <label
          className="font-lato text-xs hover:cursor-pointer opacity-50 hover:underline-offset-4 hover:underline"
          onClick={() => remove()}>
          <ArrowLeftIcon className="w-5 h-5" />
        </label>
        <div className="uppercase"> Mot de passe oublié ?</div>
      </div>

      <Formik
        enableReinitialize={true}
        onSubmit={resetForm}
        initialValues={{
          email: ''
        }}>
        <Form className="h-full w-full flex-row">
          <div className="mt-2 flex gap-x-2 mobile:flex-col mobile:gap-y-2">
            <div className="flex flex-col w-full">
              <label htmlFor="email" className="mb-1 font-lato text-xs opacity-50">
                Email
              </label>
              <Field
                id="email"
                name="email"
                placeholder="jane@exemple.com"
                type="email"
                className="p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-non font-lato"
              />
            </div>
          </div>
          <button
            className="bg-black flex justify-center items-center w-full py-[18px] mt-2"
            type="submit">
            <span className="text-white text-[15px] font-normal leading-[22.5px] uppercase font-lato">
              Soumettre
            </span>
          </button>
        </Form>
      </Formik>
    </div>
  );
};
