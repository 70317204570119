import React, { useState } from 'react';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userStore } from '../stores/UserStore.atom';
import { ArrowLeftIcon, SideBarIcon } from '../Icon';
import MobileSideBar from './MobileSidebar';
import SideBar from './Sidebar';
import { User } from '../pages/types/user.type';
import { ThemeType } from '../@types/Theme.type';
import { themeStore } from '../stores/ThemeStore.atom';
import classNames from 'classnames';

type LayoutProps = {};

const Layout: React.FC<LayoutProps> = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState<User | undefined>(userStore);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useRecoilValue<ThemeType | undefined>(themeStore);

  const handleLogout = async () => {
    try {
      setAuth({});
      localStorage.removeItem('auth');
      setUser(undefined);
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };
  const handleSidebar = async () => {
    setIsSidebarOpen(false);
  };

  return (
    <React.Fragment>
      <div
        className="h-screen flex flex-col"
        style={{ backgroundColor: `${theme?.brand.colors.primary[0].background}` }}>
        <div className="flex justify-center items-center w-full pt-4 pb-3 verticalMobile:h-24">
          <Link to="https://codress.com">
            <img
              src={theme?.brand?.logo?.image?.url}
              alt="logo.codress"
              className="w-52 verticalMobile:w-40 h-auto"
            />
          </Link>
          <button
            className="absolute top-0 left-0 mt-2 ml-2 w-10 h-10 mobile:flex hidden justify-center items-center"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <SideBarIcon />
          </button>
          <Link
            to="https://codress.com"
            className="absolute top-0 left-2 mt-2 ml-2 w-auto h-8 flex mobile:hidden font-lato text-xs justify-center items-center uppercase gap-x-3">
            <ArrowLeftIcon className="w-4 h-4" /> Continuer mon shopping
          </Link>
        </div>
        <div
          className={classNames(
            `flex h-full px-20 pt-12 verticalMobile:pt-12 mobile:px-3 flex-row md:px-10`
          )}
          style={{ backgroundColor: `${theme?.brand.colors.primary[0].background}` }}>
          <SideBar handleLogout={handleLogout} />
          <div className="w-full relative">
            <Outlet />
          </div>
          <div
            className={`${
              isSidebarOpen ? 'block' : 'hidden'
            } fixed inset-0 z-10 bg-gray-900 bg-opacity-90 w-full h-full`}
            onClick={() => setIsSidebarOpen(false)}></div>
          <div
            style={{ backgroundColor: `${theme?.brand.colors.primary[0].background}` }}
            className={`${
              isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            } fixed top-0 left-0 z-50 h-full verticalMobile:w-[93%] md:max-w-xs overflow-y-auto transform transition-transform duration-300 ease-in-out`}>
            {user && (
              <MobileSideBar
                theme={theme}
                user={user}
                handleSidebar={handleSidebar}
                handleLogout={handleLogout}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
