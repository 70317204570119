import axios from 'axios';
const base_url = process.env.REACT_APP_DEVELOP_API;

const customerService = {
  async getAllInvoices(id: string, stripeCustomerId: string) {
    return axios.get(`${base_url}/customer/invoice/${id}/${stripeCustomerId}`);
  },

  async getNextInvoice(id: string, stripeCustomerId: string) {
    return axios.get(`${base_url}/customer/invoice/next/${id}/${stripeCustomerId}`);
  },
  async getAllPaymentMethod(id: string, stripeCustomerId: string) {
    return axios.get(`${base_url}/customer/method/payment/${id}/${stripeCustomerId}`);
  },
  async setPaymentMethodToCustomer(
    id: string,
    stripeCustomerId: string,
    data: { paymentId: string }
  ) {
    return axios.post(`${base_url}/customer/method/payment/${id}/${stripeCustomerId}`, data);
  },
  async getSubscription(id: string, stripeCustomerId: string) {
    return axios.get(`${base_url}/customer/subscription/${id}/${stripeCustomerId}`);
  },
  async deletePaymentMethodById(id: string, stripeCustomerId: string, paymentMethodId: string) {
    return axios.delete(
      `${base_url}/customer/method/payment/${id}/${stripeCustomerId}/${paymentMethodId}`
    );
  },
  async updatePaymentMethodToSubscription(
    id: string,
    stripeCustomerId: string,
    data: { paymentMethodId: string; subscriptionId: string }
  ) {
    return axios.put(`${base_url}/customer/method/payment/${id}/${stripeCustomerId}`, data);
  },
  async checkCustomerExist(email: string) {
    return axios.get(`${base_url}/customer/verification/${email}`);
  }
};
export default customerService;
