import React from 'react';
import classNames from 'classnames';

interface ReturnStepperProps {
  stepStatus: string;
}
const ReturnStepper: React.FC<ReturnStepperProps> = ({ stepStatus }) => {
  return (
    <ol className="flex items-center w-full space-y-0 justify-center flex-row verticalMobile:flex-col px-5">
      <div className="flex w-full items-center flex-row">
        <div
          className={classNames(
            stepStatus === 'REQUEST' && '',
            'flex items-center font-medium px-2 py-5 w-full'
          )}>
          <span
            className={classNames(
              stepStatus === 'REQUEST' && 'bg-black text-white',
              'w-5 h-5 p-3.5 rounded-full flex border justify-center items-center mr-3 text-sm text-black'
            )}>
            01
          </span>
          <h4
            className={classNames(
              stepStatus === 'REQUEST' ? 'text-black' : 'text-gray-600'
            )}>{`Retourner l'article`}</h4>
        </div>
        <svg
          className="w-5 h-5 rtl:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 12 10">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.4"
            d="m7 9 4-4-4-4M1 9l4-4-4-4"
          />
        </svg>
      </div>
      <div className="flex w-full items-center flex-row ">
        <div
          className={classNames(
            stepStatus === 'TICKET' && '',
            'flex items-center font-medium px-2 py-5 w-full rounded-lg'
          )}>
          <span
            className={classNames(
              stepStatus === 'TICKET' && 'bg-gray-600 text-white',
              'w-5 h-5 p-3.5 border rounded-full flex justify-center items-center mr-3 text-sm'
            )}>
            02
          </span>
          <h4
            className={classNames(
              stepStatus === 'TICKET' ? 'text-black' : 'text-gray-600',
              'text-base'
            )}>
            Télécharger le bon de retour
          </h4>
        </div>
        <svg
          className="w-5 h-5 rtl:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 12 10">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.4"
            d="m7 9 4-4-4-4M1 9l4-4-4-4"
          />
        </svg>
      </div>
      <div className="flex w-full items-center flex-row ">
        <div
          className={classNames(
            stepStatus === 'FIND' && '',
            'flex items-center font-medium px-2 py-5 w-full rounded-lg'
          )}>
          <span
            className={classNames(
              stepStatus === 'FIND' && 'bg-black text-white',
              'w-5 h-5 p-3.5 border rounded-full  flex justify-center items-center mr-3 text-sm'
            )}>
            03
          </span>
          <h4
            className={classNames(
              stepStatus === 'FIND' ? 'text-black' : 'text-gray-600',
              'text-base'
            )}>
            Trouver ou le déposer
          </h4>
        </div>
      </div>
    </ol>
  );
};
export default ReturnStepper;
