import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

interface DeliveryStepperProps {
  status: string;
}
const DeliveryStepper: React.FC<DeliveryStepperProps> = ({ status }) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (status === 'En cours de preparation') {
      setStep(1);
    }
    if (status === 'En cours de livraison') {
      setStep(2);
    }
    if (status === 'En cours de location') {
      setStep(3);
    }
    if (status === 'Retour') {
      setStep(4);
    }
  }, [status]);

  const defineStep1 = (): string => {
    if (step >= 1) {
      return 'bg-[#222122]';
    }
    return 'bg-gray-400 opacity-50';
  };
  const defineStep2 = (): string => {
    if (step >= 2) {
      return 'bg-[#222122]';
    }
    return 'bg-gray-400 opacity-50';
  };
  const defineStep3 = (): string => {
    if (step >= 3) {
      return 'bg-[#222122]';
    }
    return 'bg-gray-400 opacity-50';
  };
  const defineStep4 = (): string => {
    if (step === 4) {
      return 'bg-[#222122]';
    }
    return 'bg-gray-400 opacity-50';
  };

  return (
    <div className="flex items-end max-w-screen-lg mx-auto">
      <div className="w-full">
        <h6 className="text-sm font-lato text-gray-500 mb-2 mr-4">En cours de preparation</h6>
        <div className="flex items-center w-full">
          <div
            className={classNames(
              defineStep1(),
              'w-2 h-2 shrink-0 p-1.5 flex items-center justify-center rounded-full'
            )}></div>
          <div className={classNames(defineStep2(), 'w-full h-[3px]')}></div>
        </div>
      </div>
      <div className="w-full">
        <h6 className="text-sm text-gray-500 font-lato mb-2 mr-4">En cours de livraison</h6>
        <div className="flex items-center w-full">
          <div
            className={classNames(
              defineStep2(),
              'w-2 h-2 shrink-0 p-1.5 flex items-center justify-center rounded-full'
            )}></div>
          <div className={classNames(defineStep3(), 'w-full h-[3px]')}></div>
        </div>
      </div>
      <div className="w-full">
        <h6 className="text-sm text-gray-500 font-lato mb-2 mr-4">En cours de location</h6>
        <div className="flex items-center w-full">
          <div
            className={classNames(
              defineStep3(),
              'w-2 h-2 shrink-0 p-1.5 flex items-center justify-center rounded-full'
            )}></div>
          <div className={classNames(defineStep4(), 'w-full h-[3px]')}></div>
        </div>
      </div>
      <div className="">
        <h6 className="text-sm text-gray-500 font-lato w-max mb-2">Retour</h6>
        <div className="flex items-center">
          <div
            className={classNames(
              defineStep4(),
              'w-2 h-2 shrink-0 p-1.5 flex items-center justify-center rounded-full'
            )}></div>
        </div>
      </div>
    </div>
  );
};
export default DeliveryStepper;
