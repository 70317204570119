import React from 'react';
import {
  AddressIcon,
  ArrowLeftIcon,
  CardIcon,
  CloseIcon,
  CommandeIcon,
  InfoIcon,
  InvoiceIcon,
  LogoutIcon
} from '../Icon';
import { Link } from 'react-router-dom';
import { User } from '../pages/types/user.type';
import { ThemeType } from '../@types/Theme.type';

interface MobileSideBarProps {
  user: User;
  theme: ThemeType | undefined;
  handleSidebar: () => void;
  handleLogout: () => void;
}
const MobileSideBar: React.FC<MobileSideBarProps> = ({
  user,
  handleSidebar,
  handleLogout,
  theme
}) => {
  return (
    <div className="py-4">
      <div className="flex justify-between items-center mb-5 px-4">
        <img src={theme?.brand?.logo?.image?.url} alt="logo.codress" className="w-40" />
        <button onClick={handleSidebar} className="">
          <CloseIcon />
        </button>
      </div>
      <ul className="gap-y-4 flex flex-col mt-9 px-4">
        <li>
          <div className="hover:border-l-2 flex items-center pl-2">
            <CommandeIcon />
            <Link
              className="pl-4 font-lato text-lg text-gray-800 uppercase"
              to="/home/wardrobe"
              onClick={handleSidebar}>
              Mes commandes
            </Link>
          </div>
        </li>
        <li>
          <div className="hover:border-l-2 flex items-center pl-2">
            <InfoIcon />
            <Link
              className="pl-4 font-lato text-lg text-gray-800 uppercase"
              to="/home/info"
              onClick={handleSidebar}>
              Mon compte
            </Link>
          </div>
        </li>
        <li>
          <div className="hover:border-l-2 flex items-center pl-2">
            <AddressIcon />
            <Link
              className="pl-4 font-lato text-lg text-gray-800 uppercase"
              to="/home/address"
              onClick={handleSidebar}>
              Mes adresses
            </Link>
          </div>
        </li>
        <li>
          <div className="hover:border-l-2 flex items-center pl-2">
            <CardIcon />
            <Link
              className="pl-4 font-lato text-lg text-gray-800 uppercase"
              to="/home/paymentMethode"
              onClick={handleSidebar}>
              Moyen de paiement
            </Link>
          </div>
        </li>
        <li>
          <div className="hover:border-l-2 flex items-center pl-2">
            <InvoiceIcon />
            <Link
              className="pl-4 font-lato text-lg text-gray-800 uppercase"
              to="/home/payment"
              onClick={handleSidebar}>
              Mes paiements
            </Link>
          </div>
        </li>
      </ul>
      {user && (
        <>
          <div className="flex absolute bottom-20 flex-col w-full">
            <div className="flex items-center mobile:px-5">
              <div className="flex items-center ml-1">
                <ArrowLeftIcon className="w-5 h-5" />
                <Link
                  className="pl-4 font-lato text-sm text-gray-800 uppercase w-2/3"
                  to="https://codress.com"
                  onClick={handleSidebar}>
                  Continuer mon shopping
                </Link>
              </div>
            </div>
          </div>
          <div className="flex absolute bottom-8 flex-col w-full">
            <div className="flex items-center mobile:px-5">
              <div className="flex items-center hover:border-l-2">
                <LogoutIcon />
                <button
                  className="pl-4 font-lato text-sm text-gray-800 uppercase"
                  onClick={handleLogout}>
                  SE DÉCONNECTER
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MobileSideBar;
