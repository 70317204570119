import React, { useEffect, useState } from 'react';
import ordersService from '../../../API/orders/Orders.service';
import { useRecoilValue } from 'recoil';
import { userStore } from '../../../stores/UserStore.atom';
import classNames from 'classnames';
import { CloseIcon, DownloadIcon } from '../../../Icon';
import { User } from '../../types/user.type';
import { OrderInfo } from '../../../@types/Product.type';
import { toast } from 'react-toastify';
import ReturnStepper from './ReturnStepper';

interface ProductModalProps {
  orderInfo: OrderInfo;
  onClose: () => void;
}

const ProductModal: React.FC<ProductModalProps> = ({ orderInfo, onClose }) => {
  const user = useRecoilValue<User | undefined>(userStore);
  const [ticket, setTicket] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stepStatus, setStepStatus] = useState<string>('REQUEST');

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const id = orderInfo?.orderId.split('/');
        toast.success('Nous générons votre étiquette de retour');
        await ordersService.returnOrderGenerateTicket(id[4], user).then((res) => {
          if (res.data) {
            setStepStatus('TICKET');
            setTicket(res.data);
            setIsLoading(false);
          }
        });
      } catch (e) {
        toast.error("Une erreur c'est produite veuillez recommencer plus tard");
        console.error(e);
        setIsLoading(false);
      }
    };
    if (orderInfo.status === 'Retour') {
      setIsLoading(true);
      fetchTicket();
    }
  }, [orderInfo]);
  const returnProduct = async () => {
    try {
      setIsLoading(true);
      const id = orderInfo?.orderId.split('/');
      const tmp = await ordersService.returnOrderItemById(id[4], user, orderInfo);
      if (tmp && tmp.data?.returnRequest?.return?.status === 'REQUESTED') {
        toast.success(
          'Votre demande de retour a été crée avec succès. Nous génerons votre etiquette de retour'
        );
        await ordersService.returnOrderGenerateTicket(id[4], user).then((res) => {
          if (res.data) {
            setStepStatus('TICKET');
            setTicket(res.data);
            setIsLoading(false);
          }
        });
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      toast.error("Une erreur c'est produite veuillez recommencer plus tard");
      onClose();
    }
  };

  return (
    <div className="fixed z-40 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex w-full justify-end">
              <button onClick={onClose} type="button">
                <CloseIcon />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900 font-lato"
                  id="modal-headline">
                  {orderInfo.name}
                </h3>
                <div className="mt-2 font-lato">
                  <p className="text-sm text-gray-500">{orderInfo.name}</p>
                  <p className="text-sm text-gray-500">{orderInfo.orderName}</p>
                </div>
              </div>
            </div>
          </div>
          <ReturnStepper stepStatus={stepStatus} />
          <div className="px-4 py-3 gap-y-4 sm:px-6 flex flex-col">
            {stepStatus === 'REQUEST' && (
              <div>
                {isLoading ? (
                  <div className="flex w-full flex-row h-auto items-center p-6 justify-center gap-x-3">
                    <div className="flex-col gap-4 flex">
                      <div className="w-6 h-6 border-2 text-black text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-gray-800 rounded-full"></div>
                    </div>
                    <span className="text-black text-[15px] font-normal leading-[22.5px] animate-pulse font-lato">
                      {`Retour en cours de traitement`}
                    </span>
                  </div>
                ) : (
                  <button
                    className={classNames(
                      (orderInfo.fulfillmentStatus !== 'fulfilled' ||
                        orderInfo.status === 'Retour') &&
                        'pointer-events-none opacity-50',
                      'bg-[#222122] flex justify-center items-center p-4 w-full hover:bg-white text-white hover:outline hover:outline-1 hover:text-black'
                    )}
                    onClick={returnProduct}>
                    <span className="text-[15px] font-normal leading-[22.5px] uppercase font-lato">
                      {`Retourner l'article`}
                    </span>
                  </button>
                )}
              </div>
            )}

            {stepStatus === 'TICKET' && ticket && (
              <a
                href={ticket.LabelURL[0]}
                className="lex w-full"
                download
                target="_blank"
                rel="noreferrer">
                <button
                  className={classNames(
                    orderInfo.fulfillmentStatus !== 'fulfilled' && 'pointer-events-none opacity-50',
                    'bg-[#222122] flex justify-center items-center p-4 w-full'
                  )}
                  onClick={() => setStepStatus('FIND')}>
                  <DownloadIcon />
                  <span className="text-white text-[15px] font-normal leading-[22.5px] uppercase font-lato">
                    {`Télécharger le bon de retour `}
                  </span>
                </button>
              </a>
            )}
            {stepStatus === 'FIND' && ticket && (
              <a
                href="https://www.mondialrelay.fr/trouver-le-point-relais-le-plus-proche-de-chez-moi/"
                className="lex w-full">
                <button
                  className={classNames(
                    orderInfo.fulfillmentStatus !== 'fulfilled' && 'pointer-events-none opacity-50',
                    'bg-[#222122] flex justify-center items-center p-4 w-full'
                  )}>
                  <DownloadIcon />
                  <span className="text-white text-[15px] font-normal leading-[22.5px] uppercase font-lato">
                    {`Trouver le point relai le plus proche`}
                  </span>
                </button>
              </a>
            )}
            <button
              onClick={onClose}
              type="button"
              className="w-full justify-center border border-black shadow-sm p-4 text-base font-medium text-black font-lato">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductModal;
