import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/Login/LoginPage';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

import RequireAuth from './components/RequireAuth';
import { AuthProvider } from './context/AuthProvider';
import WardrobePage from './pages/Wardrobe/WardrobePage';
import Layout from './components/Layout';
import CustomerPage from './pages/Customer/CustomerPage';
import PaymentPage from './pages/Payment/PaymentPage';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ThemeProvider } from './context/ThemeProvider';
import AddressPage from './pages/Address/AddressPage';
import { PaymentMethodePage } from './pages/PaymentMethod/PaymentMethodePage';

// eslint-disable-next-line no-undef
const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_PU_KEY as string);

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Navigate to="/login" replace />,
      errorElement: <ErrorPage />
    },
    {
      path: '/error',
      element: <ErrorPage />
    },
    {
      path: '/login',
      element: <LoginPage />,
      errorElement: <ErrorPage />
    },
    {
      element: <RequireAuth />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/home',
          element: <Navigate to="/home/wardrobe" replace />,
          errorElement: <ErrorPage />
        },
        {
          path: '/home',
          element: <Layout />,
          errorElement: <ErrorPage />,
          children: [
            {
              index: true,
              path: 'wardrobe',
              element: <WardrobePage />
            },
            {
              path: 'info',
              element: <CustomerPage />
            },
            {
              path: 'paymentMethode',
              element: <PaymentMethodePage />
            },
            {
              path: 'address',
              element: <AddressPage />
            },
            {
              path: 'payment',
              element: <PaymentPage />
            }
          ]
        }
      ]
    }
  ]);

  return (
    <RecoilRoot>
      <div>
        <ThemeProvider>
          <ToastContainer />
          <AuthProvider>
            <Elements stripe={stripePromise}>
              <RouterProvider router={router} />
            </Elements>
          </AuthProvider>
        </ThemeProvider>
      </div>
    </RecoilRoot>
  );
}

export default App;
