import React from 'react';

export const NoImageIcon: React.FC = () => {
  return (
    <svg width="78" height="81" viewBox="0 0 78 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.3694 0.942507L6.17639 7.53351C2.92316 7.9607 0.632197 10.9443 1.05939 14.1975L8.70989 72.459C9.13708 75.7122 12.1207 78.0032 15.3739 77.576L65.5669 70.985C68.8201 70.5578 71.1111 67.5742 70.6839 64.321L63.0334 6.05951C62.6062 2.80627 59.6226 0.515315 56.3694 0.942507Z"
        fill="#D2D2D2"
      />
      <path
        d="M70.9901 9.48999H20.2901C17.004 9.48999 14.3401 12.1539 14.3401 15.44V74.29C14.3401 77.5761 17.004 80.24 20.2901 80.24H70.9901C74.2762 80.24 76.9401 77.5761 76.9401 74.29V15.44C76.9401 12.1539 74.2762 9.48999 70.9901 9.48999Z"
        fill="white"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9099 56.05L32.0499 39.52C32.1497 39.2785 32.3189 39.0721 32.536 38.9268C32.7532 38.7815 33.0086 38.704 33.2699 38.704C33.5312 38.704 33.7866 38.7815 34.0038 38.9268C34.221 39.0721 34.3902 39.2785 34.4899 39.52L40.2399 48.89C40.3376 49.1323 40.5054 49.3398 40.7219 49.486C40.9384 49.6321 41.1937 49.7102 41.4549 49.7102C41.7161 49.7102 41.9714 49.6321 42.1879 49.486C42.4044 49.3398 42.5722 49.1323 42.6699 48.89L52.5099 32.89C52.6076 32.6477 52.7754 32.4402 52.9919 32.294C53.2084 32.1479 53.4637 32.0698 53.7249 32.0698C53.9861 32.0698 54.2414 32.1479 54.4579 32.294C54.6744 32.4402 54.8423 32.6477 54.9399 32.89L69.3799 56.42L21.9099 56.05Z"
        fill="white"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.1299 37.3C43.8917 37.3 45.3199 35.8718 45.3199 34.11C45.3199 32.3482 43.8917 30.92 42.1299 30.92C40.3681 30.92 38.9399 32.3482 38.9399 34.11C38.9399 35.8718 40.3681 37.3 42.1299 37.3Z"
        fill="white"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
