import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { User } from '../../types/user.type';
import { userStore } from '../../../stores/UserStore.atom';

import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { CUSTOMER_RECOVER, CUSTOMER_UPDATE } from '../../../API/queries/Customer.query';
import { Formik, Field, Form } from 'formik';
import { cutomerValidationSchema } from '../utils/CutomerValidationSchema';
import useAuth from '../../../hooks/useAuth';
import { customerUpdateFormType } from '../types/CustomerUpdateForm.type';
import { toast } from 'react-toastify';

export const Account: React.FC = () => {
  const user = useRecoilValue<User | undefined>(userStore);

  const { auth } = useAuth();

  const [recoverCustomerQuery] = useMutation<any>(CUSTOMER_RECOVER, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    }
  });

  const resetForm = async () => {
    try {
      const response = await recoverCustomerQuery({
        variables: {
          email: user?.email ?? ''
        }
      });
      if (response && response.data.customerRecover.userErrors.length > 0) {
        toast.error("Une erreur s'est produite veuillez recommencer plus tard.");
      } else {
        toast.success(
          'Vous devriez recevoir rapidement un email avec de plus amples instructions.'
        );
      }
    } catch (e) {
      toast.error("Une erreur s'est produite veuillez recommencer plus tard.");
    }
  };

  const [updateCustomerQuery] = useMutation<any>(CUSTOMER_UPDATE, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    }
  });

  const defaultValue = useMemo(() => {
    return {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      phone: user?.phone ?? '+33',
      acceptsMarketing: user?.acceptsMarketing ?? false
    };
  }, [user]);
  const updateCustomer = async (values: customerUpdateFormType) => {
    const response = await updateCustomerQuery({
      variables: {
        customerAccessToken: auth.accessToken,
        lastName: values.lastName,
        firstName: values.firstName,
        phone: values.phone,
        acceptsMarketing: values.acceptsMarketing
      }
    });
    if (response?.data?.customerUpdate.customer) {
      toast.success('Votre compte a ete modifié avec succes');
    } else {
      toast.error("Une erreur s'est produite veuillez recommencer plus tard.");
    }
  };

  return (
    <div className="gap-y-2 flex flex-col">
      {user && (
        <Formik
          enableReinitialize={true}
          validationSchema={cutomerValidationSchema}
          initialValues={defaultValue}
          onSubmit={updateCustomer}>
          {({ dirty, errors, touched }) => (
            <Form className="flex flex-col w-2/3 verticalMobile:w-full mobile:w-full h-auto pt-2 gap-y-2">
              <div className="flex flex-row verticalMobile:flex-col verticalMobile:gap-y-2 gap-x-2">
                <input
                  type="email"
                  value={user?.email}
                  required
                  disabled={true}
                  className={classNames(
                    'p-[15px] flex h-[49px] text-sm border border-solid border-gray-300 shadow-non font-lato w-full opacity-50'
                  )}
                />
                <button
                  onClick={resetForm}
                  className="hover:bg-[#222122] font-lato bg-white border border-[#222122] flex justify-center items-center p-2 w-full text-black hover:text-white cursor-pointer">
                  Modifier votre mot de passe
                </button>
              </div>
              <div className="border-b-[1.5px]"></div>
              <div className="flex justify-between gap-x-2 verticalMobile:flex-col verticalMobile:gap-y-2">
                <div className="flex flex-col w-full">
                  <Field
                    id="firstName"
                    name="firstName"
                    placeholder="Prénom"
                    type="text"
                    className={classNames(
                      errors.firstName && touched.firstName && 'border-red-500',
                      'p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato'
                    )}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <Field
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Nom"
                    className={classNames(
                      errors.lastName && touched.lastName && 'border-red-500',
                      'p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato'
                    )}
                  />
                </div>
              </div>
              <Field
                id="phone"
                name="phone"
                type="+33"
                placeholder="Tel mobile"
                className={classNames(
                  errors.phone && touched.phone && 'border-red-500',
                  'p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato'
                )}
              />
              <div className="flex flex-row items-center gap-x-3">
                <Field
                  id="acceptsMarketing"
                  name="acceptsMarketing"
                  type="checkbox"
                  className={classNames(
                    errors.acceptsMarketing && touched.acceptsMarketing && 'border-red-500',
                    'p-[15px] flex h-[20px] w-[20px] border border-solid border-gray-300 shadow-non font-lato rounded'
                  )}
                />
                <span className="w-full font-lato text-base">
                  Recevoir nos notifications par email
                </span>
              </div>
              <button
                type="submit"
                className={classNames(
                  !dirty && 'opacity-50 pointer-events-none',
                  'bg-[#222122] flex justify-center items-center p-2 w-full text-white'
                )}>
                Enregistrer les modifications
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
