import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { useMutation } from '@apollo/client';
import { CUSTOMER_CREATE, CUSTOMER_ACCESS_TOKEN_CREATE } from '../../../API/queries/Customer.query';
import { customerCreationType } from '../@types/customerCreation.type';
import { accessTokenType } from '../../types/accessToken.type';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { EyeIcon, EyeSlashIcon, LoadingIcon } from '../../../Icon';
import customerService from '../../../API/customer/Customer.service';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

interface RegisterFormProps {
  remove: () => void;
}
export const RegisterForm: React.FC<RegisterFormProps> = ({ remove }) => {
  const { setAuth } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const [isConfirmedPassword, setIsConfirmedPassword] = useState<{
    isConfirm: boolean;
    message: string;
  }>({ isConfirm: true, message: '' });
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Prénom est requis'),
    lastName: Yup.string().required('Nom est requis'),
    email: Yup.string().email('Email invalide').required('Email est requis'),
    phone: Yup.string()
      .matches(/^\+33\d{9}$/, 'Numéro de téléphone invalide')
      .required('Numéro de téléphone est requis'),
    password: Yup.string()
      .min(6, 'Le mot de passe doit comporter au moins 6 caractères')
      .required('Mot de passe est requis'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Les mots de passe doivent correspondre')
      .required('Confirmation du mot de passe est requis'),
    marketing: Yup.boolean()
  });

  const [createCustomerQuery] = useMutation<customerCreationType>(CUSTOMER_CREATE, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    }
  });

  const [customerAccessTokenCreate] = useMutation<accessTokenType>(CUSTOMER_ACCESS_TOKEN_CREATE, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    }
  });

  const createCustomer = async (values: any) => {
    setIsLoading(true);
    try {
      if (values.password !== values.confirmPassword) {
        setIsConfirmedPassword({ isConfirm: false, message: 'Bad password' });
      } else if (values.password.length < 5) {
        setIsConfirmedPassword({ isConfirm: false, message: '5 caractères au minimum' });
      } else {
        await customerService.checkCustomerExist(values.email).then(async () => {
          const response = await createCustomerQuery({
            variables: {
              email: values.email,
              password: values.password,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              acceptsMarketing: values.marketing
            }
          });
          if (response.data?.customerCreate?.userErrors) {
            if (
              response.data?.customerCreate?.userErrors &&
              response.data?.customerCreate?.userErrors[0]
            ) {
              toast.error(response.data?.customerCreate?.userErrors[0].message);
            }
          }

          if (response.data?.customerCreate?.customer) {
            const res = await customerAccessTokenCreate({
              variables: {
                email: values.email,
                password: values.password
              }
            });
            if (res?.data?.customerAccessTokenCreate.customerAccessToken) {
              toast.success('Felicitation votre compte à bien était créé');
              setIsLoading(false);
              const accessToken =
                res?.data?.customerAccessTokenCreate?.customerAccessToken?.accessToken;
              setAuth({ accessToken });
              navigate('/home');
            }
          }
        });
      }
    } catch (e: any) {
      toast.error(e.message);
      setIsError(true);
      setIsLoading(false);
      console.error(e);
    }
  };
  return (
    <div className="bg-white flex flex-col py-[30px] px-[24px] justify-end items-start w-full border border-solid border-gray-100 shadow-non">
      <div className="flex justify-between w-full items-center">
        <div className="mb-5 uppercase font-lato">Nouvel utilisateur ?</div>
        <label
          className="font-lato text-xs hover:cursor-pointer opacity-50 hover:underline-offset-4 hover:underline"
          onClick={() => remove()}>
          Deja client ?
        </label>
      </div>
      <Formik
        enableReinitialize={true}
        onSubmit={createCustomer}
        validationSchema={validationSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          marketing: true,
          password: '',
          confirmPassword: '',
          phone: '+33'
        }}>
        {({ errors, touched }) => (
          <Form className="h-full w-full flex-row">
            {isError && (
              <div className="flex justify-center font-lato text-sm text-red-500 mb-2 ">
                Une erreur est survenu veuillez re-essayer
              </div>
            )}
            <div className="flex gap-x-2 mobile:flex-col mobile:gap-y-2">
              <div className="flex flex-col w-full">
                <label htmlFor="firstName" className="mb-1 font-lato text-xs opacity-50">
                  Prénom
                </label>
                <Field
                  id="firstName"
                  name="firstName"
                  placeholder="Jane"
                  type="text"
                  className={classNames(
                    'p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-none font-lato',
                    { 'border-red-500': errors.firstName && touched.firstName }
                  )}
                />
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="lastName" className="mb-1 font-lato text-xs opacity-50">
                  Nom
                </label>
                <Field
                  id="lastName"
                  name="lastName"
                  placeholder="Jane"
                  type="text"
                  className={classNames(
                    'p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-none font-lato',
                    { 'border-red-500': errors.lastName && touched.lastName }
                  )}
                />
              </div>
            </div>
            <div className="mt-2 flex gap-x-2 mobile:flex-col mobile:gap-y-2">
              <div className="flex flex-col w-[65%] mobile:w-full">
                <label htmlFor="email" className="mb-1 font-lato text-xs opacity-50">
                  Email
                </label>
                <Field
                  id="email"
                  name="email"
                  placeholder="jane@exemple.com"
                  type="email"
                  className={classNames(
                    'p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-none font-lato',
                    { 'border-red-500': errors.email && touched.email }
                  )}
                />
              </div>
              <div className="flex flex-col mobile:w-full w-[35%]">
                <label htmlFor="phone" className="mb-1 font-lato text-xs opacity-50">
                  Tel mobile
                </label>
                <Field
                  id="phone"
                  name="phone"
                  placeholder="+33"
                  type="text"
                  className={classNames(
                    'p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-none font-lato',
                    { 'border-red-500': errors.phone && touched.phone }
                  )}
                />
              </div>
            </div>
            <div className="flex gap-x-2 mt-2 mobile:gap-y-2 mobile:flex-col">
              <div className="flex flex-col w-full">
                <label htmlFor="password" className="mb-1 font-lato text-xs opacity-50">
                  Mot de passe
                </label>
                <div className="flex w-full relative">
                  <Field
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    required
                    placeholder="Votre mot de passe"
                    className={classNames(
                      'p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-none font-lato w-full',
                      { 'border-red-500': errors.password && touched.password }
                    )}
                  />
                  <div
                    className=" absolute top-[50%] transform -translate-y-1/2  right-2 text-gray-400 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="confirmPassword" className="mb-1 font-lato text-xs opacity-50">
                  Mot de passe confirmation
                </label>
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Mot de passe"
                  type="password"
                  className={classNames(
                    'p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-none font-lato',
                    { 'border-red-500': errors.confirmPassword && touched.confirmPassword }
                  )}></Field>
              </div>
            </div>
            {!isConfirmedPassword.isConfirm && (
              <div className="flex justify-center font-lato text-sm text-red-500 mt-1">
                {isConfirmedPassword.message}
              </div>
            )}
            <div className="mt-2">
              <div className="flex flex-row w-full items-center h-full py-1 gap-x-4">
                <Field
                  id="marketing"
                  name="marketing"
                  placeholder="confirmPassword"
                  type="checkbox"
                  className=""
                />
                <label htmlFor="marketing" className=" font-lato text-xs opacity-50">
                  Recevez nos invitations et soyez au courant de tout avant tout le monde.
                </label>
              </div>
            </div>
            <button
              className={classNames(
                isLoading && 'bg-white border-black border',
                'bg-black text-white flex justify-center items-center w-full py-[18px] mt-2'
              )}
              type="submit">
              <span
                className={classNames(
                  isLoading && 'text-black flex justify-center items-center gap-x-3 ',
                  'text-[15px] font-normal leading-[22.5px] uppercase font-lato'
                )}>
                {isLoading && <LoadingIcon />}
                {isLoading ? 'Creation de votre compte en cours' : 'Créer votre compte'}
              </span>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
