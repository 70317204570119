import axios from 'axios';
import { BuyingInfo } from '../../pages/types/buyingInfo.type';

const base_url = process.env.REACT_APP_DEVELOP_API;

const checkoutService = {
  async buyProductOrder(customerId: string, data: { buyingInfo: BuyingInfo }) {
    return axios.post(`${base_url}/checkout/customer/${customerId}`, data);
  }
};
export default checkoutService;
